<template>
  <div class="header-layout">
      <el-row style="margin-top: 10px; margin-bottom: 20px; display: flex;">
      <el-col :span="12" style="align-self: center;">

        <div class="breadcrumb-layout">
          <el-breadcrumb separator="/">
            <div class="line"></div>
            <template v-for="(item, index) in $route.matched">
              <el-breadcrumb-item v-if="item.meta.title && index == $route.matched.length - 1" :key="item.path">
                {{ $t('router.' + item.meta.title) }}
              </el-breadcrumb-item>
            </template>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="1" :offset="11" style="align-self: center;">
        <el-tooltip :disabled="!actionButtonDesc" placement="left" :content="actionButtonDesc" effect="dark">
          <el-button v-show="actionButtonIcon" :icon="actionButtonIcon" circle class="btn-action" :style="{backgroundColor: actionButtonColor, borderColor: actionButtonColor}" @click="$emit('action', $event.target.value)"></el-button>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-button v-show="backButtonText" type="text" icon="el-icon-back" class="btn-back" @click="$router.go(-1)">{{ backButtonText }}</el-button>
    <!-- <h3 class="title">{{ title }}</h3> -->



  </div>

</template>

<script>
  export default {
    name: "Navigation",
    props: {
      title: {
        type: String,
        required: true
      },

      backButtonText: {
        type: String,
        default: ''
      },

      actionButtonIcon: {
        type: String,
        default: ''
      },

      actionButtonColor: {
        type: String,
        default: '#00BFD7'
      },

      actionButtonDesc: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style scoped>
  /* .header-layout {
    display: flex;
    margin-bottom: 10px;
    height: 50px;
    margin-bottom: 20px;
    top: 9%;
    position: absolute;
    right: 1%;
  } */
  .breadcrumb-layout {
    position: relative;
  }
  .line{
  width: 50px;
  height: 3px;
  border-bottom: 3px solid #00BFD7;
  position: absolute;
  bottom: -20%;
}

  .btn-back {
    font-size: medium;
    color: #606266;
    color: #606266;
  }

  .title {
    margin: 0 auto;
    color: #606266;
  }

  .btn-action {
    font-size: x-large;
    /* float: right; */
    width: 48px;
    height: 48px;
    color: white;
    box-shadow: 0px 1px 2px rgba(0,0,0, 0.5);
  }

  .btn-action:hover {
    box-shadow: 0px 1px 2px rgba(0,0,0, 0.5);
  }

  .btn-action:active {
    box-shadow: 0px 1px 2px rgba(0,0,0, 0.5);
  }
</style>